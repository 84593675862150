import React, { useState } from "react";
import logo from "./logo.png";
import ReactMarkdown from "react-markdown";

function LinkRenderer(props) {
  if(props.href.includes("http"))
{
  return (
    <a href={props.href} target="_blank" rel=" noopener noreferrer">
      {props.children}
    </a>
  );
} else {
  return (
    <a href={props.href}>
    {props.children}
  </a>
  )
} 
}
function PartnerInfo(props) {
  const [expand, setExpand] = useState(false);
  const [img] = useState(props.partner.fields.featuredImage.fields.file.url)


  return (
    <div
      className={`partner-button partner-button-${props.partner.fields.internalUmn} flex-grow-1`}
      // style={divStyle}
    >
  
      <div className="flex-column flex-evenly">
        <div>
        {props.partner.fields.internalUmn === true ? (
          <img src={logo} className="logo" alt="UMN logo" />
        ) : (
          <div className="logo">
            <i className="fa-solid fa-earth-americas fa-lg"></i>
          </div>
        )}
        <h2 className={`smaller ${props.partner.fields.featuredImage.fields.title !== "placeholder-image" && 'contrast'}`}> {props.partner.fields.name} {!expand ? (
            <i
              className="fa-solid fa-caret-down "
              onClick={() => setExpand(!expand)}
            ></i>
          ) : (
            <i
              className="fa-solid fa-caret-up "
              onClick={() => setExpand(!expand)}
            ></i>
          )}</h2>
        </div>
  
     
        {expand && (
          <div>
            <div className="column-sm-12 column-lg-12 flex-row">
            {props.partner.fields.tags.map((tag, i) => {
            return(
              <span key={i} className={`tag tag-${props.partner.fields.internalUmn}`}>{tag.fields.value}</span>
            )
          })}
          <a href={props.partner.fields.website} className="no-decoration" ><i className="fa-solid fa-link"></i></a>
        </div>
          <p className="partner-description">
            <ReactMarkdown components={{a: LinkRenderer}}>{props.partner.fields.shortDescription}</ReactMarkdown>
          </p>
 
          </div>
        )}
      </div>

      {expand && (
        <div className="cta-div flex-row flex-center">
          {props.partner.fields.narrative && <button><a href={`/partners/${props.partner.fields.slug}`}>Learn more</a></button>}
        </div>
      )}
    </div>
  );
}
export default PartnerInfo;

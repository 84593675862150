import React, { useEffect, useState } from "react";
import "./home.css";
import Header from "../Headers/Header";
import FeaturedProjects from "./FeaturedProjects";
import heroimage from "../images/HftF1.jpg";
import ReactMarkdown from "react-markdown";
import ReadMore from './ReadMore';
function Home(props) {
  let [index, iterate] = useState(0);
  const [currentImage, setCurrentImage] = useState({ image: "", alt: "" });
  const carousel = [
    {
      image: heroimage,
      alt: "History for the Future exhibit at Moon Palace Books",
      caption: `History for the Future exhibit installed in an open community lot in South Minneapolis. Image credit: History for the Future,
  2021.`,
    },
  ];

  useEffect(() => {
    setCurrentImage(carousel[0]);
  }, []);

  function moveThrough() {
    if (index === carousel.length - 1) {
      setCurrentImage(carousel[0]);
      iterate(0);
    } else if (index > carousel.length) {
      setCurrentImage(carousel[0]);
      iterate(0);
    } else {
      setCurrentImage(carousel[index + 1]);
      iterate(index + 1);
    }
  }

  return (
    <div className="front">
      <div className="homepage-billboard">
        <div className="front-nav">
          <Header
            title={null}
            subtitle={null}
            home={true}
            url={null}
            urlCTA={null}
          />
        </div>

        <div className="site-title-container flex-column">
          <div className="site-title">
            <h1>
              Minnesota Transform
              <br />
              <span className="smaller-text">
                A Just University for Just Futures
                <br />
              </span>
            </h1>
          </div>
          {/* mobile */}
          <div className="wrapper">
            <div className="site-title-mobile">
              <h1>
                Minnesota Transform
                <br />
                <span className="smaller">
                  A Just University for Just Futures
                </span>
              </h1>

              <img
                src={currentImage.image}
                alt={currentImage.alt}
                className="full-width home-image"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex-row flex-center hero-content align-center">
        <div className="column-lg-10 column-md-10 hero-container">
          <div className="hero-content"></div>
          <div className="flex-row flex-center no-wrap" id="image-parent-div">
      
            <img
              src={currentImage.image}
              alt={currentImage.alt}
              width="100%"
              className="hero-image column-sm-12 column-lg-10 column-md-10"
            />
          </div>

          <div className="flex-row flex-center">
            <ReactMarkdown className="photo-caption">
              {currentImage.caption}
            </ReactMarkdown>
          </div>
        </div>
        <ReadMore/>
      </div>
 
     
      <div className="home-page color-variant">
   
        <div className="flex-row flex-center">
          {/* mission */}
         
          <div className="home-sub-info flex-row flex-center" id="mission">
            <div className="column-lg-12 column-md-12">
              <div className="framed">
                <div className="flex-row flex-center">
                  <div className="column-lg-10">
                    <h2>
                      We are building towards transformative justice through
                      public humanities.
                    </h2>
                    <p>
                      {" "}
                      Minnesota Transform is a major higher education initiative
                      engaging anti-colonial and racial justice work through the
                      public humanities at the University of Minnesota, in the
                      Twin Cities, and across the state of Minnesota.
                    </p>
                    <p>
                      Supported by a Just Futures Mellon grant, MN Transform
                      puts “humanities on the front lines” through numerous
                      public and university partnerships and hundreds of student
                      internships on racial justice. On campus, MN Transform
                      lays the groundwork for structural changes that expand the
                      university’s racial justice work.
                    </p>
                    <p>
                      Minnesota Transform is part of, and helps to support,
                      universities’ reckoning with racism, dispossession and
                      exclusion, in ways that reimagine the university and
                      elaborate its possibilities.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* partners */}
        <div className="flex-row flex-center " id="partners">
          <div className="column-lg-12 column-md-12 home-sub-info">
            <div className="framed">
              <div className="flex-row flex-center">
                <div className="column-lg-10">
                  <h2>
                    We work in partnership with and for local organizations.
                  </h2>
                  <p>
                    Our partnerships include tribal and higher education
                    institutions, such as the{" "}
                    <a
                      href="https://mn.gov/indianaffairs/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Minnesota Indian Affairs Council
                    </a>
                    ,{" "}
                    <a
                      href="https://www.swcollege.edu/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Sisseton Wahpeton College
                    </a>
                    ,{" "}
                    <a
                      href="https://minneapolis.edu/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Minneapolis College
                    </a>
                    , and the&nbsp;
                    <a
                      href="https://www.humanitiesactionlab.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Humanities Action Lab
                    </a>
                    . We are also in partnership with over 16 Twin Cities arts
                    and community organizations.
                  </p>
                  <p>
                    Through these partnerships, we support Dakota and Ojibwe
                    language revitalization programs, public history and art
                    exhibitions, pedagogical workshops, multimedia storytelling,
                    decolonial digital maps, and internships and experiences for
                    students interested in working in racial justice.
                  </p>
                  <p>
                    At Minnesota Transform, we focus on the particular
                    possibilities and histories that entangle the University of
                    Minnesota in its communities. Importantly, we use the tools
                    of the humanities &mdash; historical, narrative, and
                    artistic work &mdash; to craft ethical engagements with
                    these communities. We explore what it would be like if the
                    humanities had a presence in racial justice struggles to the
                    same extent that other parts of the university have a
                    presence in the state’s business and political life.
                  </p>
                  <p>
                    In so doing we change both the knowledge that universities
                    offer, and also challenge how the university operates. Our
                    collaborations are designed and suggested by our partners,
                    working with, but not under the direction of, university
                    students, scholars, and historians. We do this not simply to
                    make up for the university’s complicity &mdash; that is, not
                    simply to right wrongs from the past &mdash; but also
                    because we think it’s how knowledge should be created. We
                    demonstrate that engaging with publics is what universities
                    should, and in fact are able to, do.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="white">
          {/* projects */}
          <div className="flex-row flex-center" id="projects">
            <div className="column-lg-10 column-md-10 home-sub-info">
              <br />
              <h2>Featured Projects</h2>
              <p>Explore the projects we've been working on.</p>
            </div>
            <div className="flex-row flex-center column-lg-12 column-md-12">
              <FeaturedProjects history={props.history} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;

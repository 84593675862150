import React, { useState } from "react";


function InternDetail(props) {
  const [visible] = useState(true);
  const [moreVisible, setMore] = useState(false);

  function reset() {
    setMore(false);
  }
  return (
    <div className={`gallery__item-interns gallery__item-interns--${props.index}`}>
  
      <div className="highlight-title-interns flex-row flex-wrap flex-between">
        {visible &&
          <h3>
            {props.person.fullName}{" "}
            {!moreVisible ? (
              <i
                className="fa-solid fa-circle-chevron-down"
                onClick={() => setMore(true)}
              ></i>
            ) : (
              <i
                className="fa-solid fa-minus fa-xs"
                onClick={() => reset()}
              ></i>
            )}
          </h3>
        }

        {moreVisible && (
          <div className="flex-row end">
            <p className="column-lg-12 intern-bio">{props.person.bio}</p>
          </div>
        )}
      </div>
      {!moreVisible ? (
        <img
          src={`${props.person.headshot.fields.file.url}`}
          alt={props.person.headshot.fields.description}
          className={`gallery__img-interns`}
        />
      ) : (
        <img
          src={`${props.person.headshot.fields.file.url}`}
          alt={props.person.headshot.fields.description}
          className={`gallery__img-interns grayed`}
        />
      )}
    </div>
  );
}

export default InternDetail;

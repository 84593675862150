import React from "react";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./themes/theme";
import { GlobalStyles } from "./themes/global";
import { useDarkMode } from "./themes/useDarkmode";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Library from './components/Projects/Projects'
import ProjectsPage from "./components/Projects/ProjectsPages";
import NoMatch from './components/404/404';
import Interns from './components/AboutUs/Interns/Interns'
import Footer from './components/Footer/Footer'; 
import Mission from './components/Mission/Mission';
import AboutUs from './components/AboutUs/AboutUs';
import NewsUpdates from './components/NewsUpdates/NewsUpdates';
import Partners from './components/Partners/Partners';
import PartnerPages from './components/Partners/PartnerPage';
import Acknowledgements from "./components/Acknowledgments/Acknowledgements";
function App(props) {
  const [theme] = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;
 
  return (
    <ThemeProvider theme={themeMode}>
      <>
        <GlobalStyles />
        <Router>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path ="/about-us"  component={AboutUs}/>
            <Route exact path ="/library"  component={Library}/>
            <Route path="/library/:slug" component={ProjectsPage} />
            <Route path="/about-us/interns-and-fellows" component={Interns}/>
            <Route path="/community-news-archive" component={NewsUpdates}/>
            <Route exact path="/partners" component={Partners}/>
            <Route path="/partners/:slug" component={PartnerPages}/>
            <Route path="/transforming-the-university" props={props} component={Mission}/>
            <Route path="/acknowledgements" component={Acknowledgements}/>
            <NoMatch />
          </Switch>
        <Footer/>
        </Router>
      </>
    </ThemeProvider>
  );
}

export default App;

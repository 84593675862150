import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import moment from "moment";

function LinkRenderer(props) {
  if (props.href.includes("http")) {
    return (
      <a href={props.href} target="_blank" rel=" noopener noreferrer">
        {props.children}
      </a>
    );
  } else {
    return <a href={props.href}>{props.children}</a>;
  }
}
function ContentDetails(props) {
  const [visible, setVisibility] = useState(false);
  const [size, setSize] = useState("");
  const [endDate, setEndDate] = useState();
  const [startDay, setStartDay] = useState()
  const [endDay, setEndDay] = useState()
  const [startDate, setStartDate] = useState();
  
  useEffect(() => {
    if (props.full === false) {
      setSize("flex-grow-1");
    } else {
      setSize("flex-grow-4");
    }
    calculateEndDate();
  }, []);
  function calculateEndDate() {
   if (props.item.fields.date !== undefined){
    let startDate = moment(props.item.fields.date).format("MMMM Do, YYYY");
    startDate = startDate.substring(0, startDate.length - 6)
    let day = moment(props.item.fields.date).format('dddd');
    setStartDay(day)
    setStartDate(startDate);
   } 
    if (props.item.fields.endTime !== undefined) {
      let endDate = moment(props.item.fields.endTime).format("MMMM Do, YYYY");
      endDate = endDate.substring(0, endDate.length - 6)
      let endDay = moment(props.item.fields.endTime).format('dddd');
      setEndDay(endDay)
      setEndDate(endDate);
    } else {
      setEndDate(null);
    }
  }
  function adjust(bool) {
    setVisibility(bool);
    if (bool === true) {
      setSize("flex-grow-3");
      document.getElementById(`${props.item.sys.id}`).scrollIntoView();
    } else {
      if (props.full === false) {
        setSize("flex-grow-1");
      } else {
        setSize("flex-grow-4");
      }
    }
  }
  return (
    <div className={`news-card ${size}`}>
       {props.item.fields.type === "photo" ? (
        <div className="col-sm-12">
          <img
            src={props.item.fields.file}
            className=" shorter"
            alt={props.item.fields.alt}
          />
        </div>
      ) : (
      <div className="text-container">
        <div
          className={`color-bar color-day-${startDay} ${props.item.fields.title === "MNT Showcase Gallery" ?('gallery'):(' ')}`}
        ></div>

        <div className="description">
          <h4 className="news-title" id={props.item.sys.id} tabIndex="-1">
            {props.item.fields.title}
          </h4>
          {visible && (
            <div>
              {props.item.fields.type === "news" && props.item.fields.date && (
                <div className="byline">
                  {moment(props.item.fields.date).format("LL")}
                </div>
              )}

              <ReactMarkdown components={{ a: LinkRenderer }}>
                {props.item.fields.description}
              </ReactMarkdown>

              {props.item.fields.link && (
                <p>
                  <a
                    href={props.item.fields.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.item.fields.linkCta}
                  </a>
                </p>
              )}
            </div>
          )}
        </div>

        {!visible && (
          <i
            className="fa-solid fa-plus more-icon"
            onClick={() => {
              adjust(true);
            }}
          ></i>
        )}
        {visible && (
          <i
            className="fa-solid fa-minus more-icon"
            onClick={() => adjust(false)}
          ></i>
        )}
      </div>
      )}
    </div>
  );
}
export default ContentDetails;

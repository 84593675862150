import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import moment from "moment";

function LinkRenderer(props) {
  if(props.href.includes("http"))
{
  return (
    <a href={props.href} target="_blank" rel=" noopener noreferrer">
      {props.children}
    </a>
  );
} else {
  return (
    <a href={props.href}>
    {props.children}
  </a>
  )
} 
}
function ContentDetails(props) {
  const [visible, setVisibility] = useState(false);
  const [size, setSize] = useState("");

  useEffect(() => {
    if (props.full === false) {
      setSize("flex-grow-1");
    } else {
      setSize("flex-grow-3");
    }
  }, []);
  function adjust(bool) {
    setVisibility(bool);
    if (bool === true) {
      setSize("flex-grow-3");
      document.getElementById(`${props.item.sys.id}`).scrollIntoView();
    } else {
      if (props.full === false) {
        setSize("flex-grow-1");
      } else {
        setSize("flex-grow-3");
      }
    }
  }
  return (
    <div className={`news-card ${size}`}>
      {/* to-do: add photos to news - if content type = photo, just show the photo. otherwise, do all this stuff */}
      {props.item.fields.type === "photo" ? (
        <div>
          <img
            src={props.item.fields.image.fields.file.url}
            className="full-width"
            alt={props.item.fields.image.fields.file.description}
          />
        </div>
      ) : (
        <div className="text-container">
          <div
            className={`color-bar color-${props.item.fields.type} color-job-${props.item.fields.internalJob}`}
          ></div>
          <p className="meta-data">
            {props.item.fields.internalJob === true && (
              <span>Minnesota Transform </span>
            )}
            {props.item.fields.type}
          </p>
          <div className="description">
            <h4 className="news-title" id={props.item.sys.id} tabIndex="-1">
              {props.item.fields.title}
            </h4>
            {visible && (
              <div>
                {props.item.fields.type === "news" &&
                  props.item.fields.date && (
                    <div className="byline">
                      {moment(props.item.fields.date).format("LL")}
                    </div>
                  )}
                {props.item.fields.type === "event" && props.item.fields.date && (
                  <div className="byline">
                    {moment(props.item.fields.date).format("MMMM Do, YYYY")} at{" "}
                    {moment(props.item.fields.date).format("LT")}
                  </div>
                )}
                {props.item.fields.type === "news" && (
                  <p className="byline">By: {props.item.fields.author}</p>
                )}
                <ReactMarkdown components={{a: LinkRenderer}}>{props.item.fields.description}</ReactMarkdown>

                {props.item.fields.link && (
                  <p>
                    <a
                      href={props.item.fields.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {props.item.fields.linkCta}
                    </a>
                  </p>
                )}
              </div>
            )}
          </div>

          {!visible && (
            <i
              className="fa-solid fa-plus more-icon"
              onClick={() => {
                adjust(true);
              }}
            ></i>
          )}
          {visible && (
            <i
              className="fa-solid fa-minus more-icon"
              onClick={() => adjust(false)}
            ></i>
          )}
        </div>
      )}
    </div>
  );
}
export default ContentDetails;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import "./footer.css";
import logo from "../images/MNT_LOGO.png";
import waves from "../images/waves.svg";

function Footer() {
  const [year, setYear] = useState(null);
  const location = useLocation()
  const [path, setPath] = useState("")


  useEffect(() => {
    let today = new Date();
    let year = today.getFullYear();
    setYear(year);
    if (location.pathname === "/"){
      setPath("home");
    }
  }, []);
 

  return (
    <div className="reset-color">
      <div className={`waves waves-${path} column-lg-12`}>
        <img src={waves} className="flipped" alt="" />
      </div>
      <footer>
        
        <div className="flex-row footer-content no-wrap column-lg-12">
    
  
          <div className="column-lg-5 column-md-5">
            <h3>Minnesota Transform</h3>
            <p>A Just University for Just Futures</p>

          </div>
         
        </div>

        <div className="flex-row flex-center footer-content">
          {year !== null && (
            <p>Unless otherwise stated, all rights reserved. &copy; {year} </p>
          )}
        </div>
      </footer>
    </div>
  );
}
export default Footer;

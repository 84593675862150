import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import news from '../images/newspaper.png'
const contentful = require("contentful");
const client = contentful.createClient({
  space: "7urij08wib2s",
  accessToken: "RlThfQO4Z7KjHdZxPiO6bTvDDxBAwJX52q7E4qX5274",
});

function ReadMore( ){
    const [content, setContent] = useState([])

    useEffect(() => {
        client
        .getEntries({ content_type: "pageOverview" })
        .then((response) => {
          let content = response.items.filter((item => item.fields.pageSlug === "home"));
            setContent(content);
          
        })
        .catch(console.error);
      }, []);
    return(
        <div className="wrapper color-variant" id="highlight">
         
              
               
                    <div className="flex-row flex-center">
                  <div className="column-lg-10 cta-text">
                
                
              {content.length > 0 && <ReactMarkdown>{content[0].fields.content}</ReactMarkdown>}
             
                </div>
            </div>
          
        </div>
    )
}
export default ReadMore;
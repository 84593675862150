import React, { useEffect, useState } from "react";
import './slider.css'

function Slider(props){
const slider = props.examples;
let [currentImage, setCurrentImage] = useState({image: '', alt: ''});
let [index, iterate] = useState(0);

useEffect(() => {
      setCurrentImage({image: slider[0].fields.file.url, alt: slider[0].fields.description})
  }, []);

  function moveThrough(){
    if(index === slider.length - 1){
     setCurrentImage({image: slider[0].fields.file.url, alt: slider[0].fields.description});
     iterate(0)
    } else if (index > slider.length) {
     setCurrentImage({image: slider[0].fields.file.url, alt: slider[0].fields.description});
     iterate(0)
    } else {
     setCurrentImage({image: slider[index+1].fields.file.url, alt: slider[index+1].fields.description});
     iterate(index+1);
    }
  }
  function moveBack(){
    if (index === 0) {
      setCurrentImage({image: slider[(slider.length)-1].fields.file.url, alt: slider[(slider.length)-1].fields.description});
      iterate(slider.length);
    } else if ( index <= slider.length) {
      setCurrentImage({image: slider[index-1].fields.file.url, alt: slider[index-1].fields.description});
      iterate(index-1);
    }
  }

 return (
    <div className="slider">
{slider.length > 1 && <div className="slider-icon-container-left"> 

<i className="fa-solid fa-angles-left fa-xl slider-icons" onClick={()=>moveBack()}></i>
</div>}
  
  
  <div className={`column-lg-12 column-md-12` }> 
     {/* to add later to this element:  onClick={()=>props.history.push(`library/${props.project.slug}`)} gallery__item--${props.index} */}
    <img
          src={currentImage.image}
          alt={currentImage.alt}
          className={`gallery__img`}
        />
</div>
{slider.length > 1 && <div className="slider-icon-container-right"> 

<i className="fa-solid fa-angles-right fa-xl slider-icons" onClick={()=>moveThrough()}></i>
</div>}
    </div>
    
 )
}
export default Slider;
// global.js
// Source: https://github.com/maximakymenko/react-day-night-toggle-app/blob/master/src/global.js#L23-L41

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  a { 
    color: ${({ theme }) => theme.link};

  }
  a[target="_blank"]::after{
    font-family: 'FontAwesome';
    content: " \f08e";
    font-size: .5em;
  }
  .about-div {
    padding: 2rem;
  }
  .align-left {
    text-align: left;
  }
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    height: 100vh;
    margin: 0px;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    transition: all 0.25s linear;
    font-size: 18px;
    scroll-behavior: smooth;
  }
 .color-emphasis {
  background-color: gold;
 }
  button {
    background-color: white;
    border: 1px solid #02213B;
    box-shadow: 2px 3px #02213B;
    padding: 1rem;
    border-radius: 5px;
    font-size: 1em !important;
    cursor: pointer;

  }
  button a {
    text-decoration: none
  }
  .align-center {
    align-items: center;
  }
  .between {
    justify-content: space-between;
  }
  .bio-pic {
    border: 10px solid ${({ theme }) => theme.containerHighlight};
  }
  .button {
    background-color: ${({ theme }) => theme.secondary};
    border: 0;
    box-shadow: 2px 3px ${({ theme }) => theme.toggleBorder};
    padding: 1rem;
    font-size: 1em;
    cursor: pointer;
  }
  .button:hover {
    box-shadow: 2px 3px ${({ theme }) => theme.containerText};
    transform: translateY(2px)
  }
  .center {
    align-self: center;
  }
  #code-editor {
    border: 10px solid ${({ theme }) => theme.toggleBorder};
    border-bottom: 10px solid ${({ theme }) => theme.secondary};
  }
  .color-bar {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: auto;
    height: 1.5em;
    background: #ffe270;
  }
  .column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .contact-icon {
    margin: .3em;
    padding: 5px;
  }
  .end {
    justify-content: flex-end;
  }
  .evenly {
    justify-content: space-evenly;
  }
  h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Condensed', sans-serif;
  color: #02213B;

}
h1 {
  font-size: 2em
}

.head {
  margin-left: 5vw;
  transform: translateY(-4rem);
  
}
.header-nav {
  padding-bottom: 1vh;
}
.head:hover {
  animation: bounce 1s infinite; 
}
.indent {
  margin-left: 1ch
}
.flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap:wrap; 
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
}
.flex-baseline {
  align-items: baseline;
}
.flex-between {
  justify-content: space-between;
}
.flex-end {
  justify-content: flex-end;
}
.flex-center{ 
  justify-content: center;
}
.flex-evenly {
  justify-content: space-evenly;
}

code {
  font-family: 'Roboto', sans-serif !important;
}
.no-left {
  margin-left: 0
}
.sub-page-header {
  text-align: center;
  padding: 1em;
  background-color: white;
 
}
.large {
  font-size: 5em;
}
.link {
  color: ${({ theme }) => theme.link};
  margin-bottom: 2em;
}


.no-color {
  text-decoration: none!important;
  margin: 5px;
  color: white;
  
}
.no-color-active {
  cursor: auto;
}
.no-color-mobile {
  background-color: transparent!important;
  text-decoration: none!important;
}
.no-decoration {
  text-decoration: none
}
.loose-page {
  padding-bottom: 25vh
}
nav > a {
  padding: .5em;
  text-decoration: none!important;
}
.no-wrap {
  flex-wrap: nowrap;
}
.number {
  background-color: ${({ theme }) => theme.highlight};
  text-align: center;
}

  .position {
    border-bottom: 3px solid ${({ theme }) => theme.containerHighlightText};
  }

  .project-billboard > h2 {
    font-size: 2em;
  }
  .project-container {
    background-color: ${({ theme }) => theme.containerBody};
    color: ${({ theme }) => theme.containerText};
    margin: 10px;
    border-radius: 4px;
    text-align: left;
    height: auto;
  }

  .skill, .value-item {
    margin: .25em;
    border-radius: 10px;
    padding: 1em;
    background-color: ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.body};
  }
  .sm-margin {
    margin: 1em
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5vh
  }
  section {
    padding: 10vh 0;
  }
  .section {
    margin-bottom: 5vh;
  }
  
  .text-left {
   text-align: left;
  }


  .tag-container { 
    padding: 1em;
    margin-bottom: 2em;
  }
  .text-center {
    text-align: center;
  }
  .text-container {
    position: relative;
    overflow: hidden;
    background-color: white;
    height: 100%;
    margin-bottom: 2px;
    padding: 2em 1.9em 1em 1.8em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 4px;
    font-size: 1.2em;
  
  }
  .toggle-div {
    display: flex; 
    flex-diretion: row;
    justify-content: flex-end;
    align-items: center; 
  }

  .project-section {
    margin: 1em;
    padding: 1em;
  }
  /* For mobile: */
[class*="column-"] {
    width: 100%;
  }
  .project-section {
    margin: 0;
    padding: 0;
  }
  .logo-subpage {
  display: none;
  }
  @media only screen and (min-width: 600px) and (max-wdith: 800px) {
    /* For tablets: */
    .column-md-1 {width: 8.33%;}
    .column-md-2 {width: 16.66%;}
    .column-md-3 {width: 25%;}
    .column-md-4 {width: 33.33%;}
    .column-md-5 {width: 41.66%;}
    .column-md-6 {width: 50%;}
    .column-md-7 {width: 58.33%;}
    .column-md-8 {width: 66.66%;}
    .column-md-9 {width: 75%;}
    .column-md-10 {width: 83.33%;}
    .column-md-11 {width: 91.66%;}
    .column-md-12 {width: 100%;}
    .wrapper {

      max-width: 75rem;
      margin-left: auto;
      margin-right: auto;
    }
    .wrapper-sm {

      max-width: 90rem;
      margin-left: auto;
      margin-right: auto;
    }
    .wrapper-lg {

      max-width: 50rem;
      margin-left: auto;
      margin-right: auto;
    }
    .project-section {
      margin: 0 ;
      padding: 0;
    }
    .logo-subpage {
     display: none
    }
    .home-logo-true {
      height: 100px;
  }
  }
  @media only screen and (min-width: 980px) {
    /* For desktop: */
    .column-lg-1 {width: 8.33%;}
    .column-lg-2 {width: 16.66%;}
    .column-lg-3 {width: 25%;}
    .column-lg-4 {width: 33.33%;}
    .column-lg-5 {width: 41.66%;}
    .column-lg-6 {width: 50%;}
    .column-lg-7 {width: 58.33%;}
    .column-lg-8 {width: 66.66%;}
    .column-lg-9 {width: 75%;}
    .column-lg-10 {width: 83.33%;}
    .column-lg-11 {width: 91.66%;}
    .column-lg-12 {width: 100%;}
    .project-section, .video-404 { 
      border-radius: 4px;
      text-align: left;
      padding-top: 1em;
      margin-top: 1em;
    }
    .logo-subpage {
      height: 150px;
      margin-left: 10px;
       margin-top: 10px;
       position: absolute;
       left: 0;
       top: 0;
       display: inherit
     }
     .home-logo-true {
      height: 250px;
  }
  .wrapper {

    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
  }
  .wrapper-sm {

    max-width: 90rem;
    margin-left: auto;
    margin-right: auto;
  }
  .wrapper-lg {

    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  
  }
  }
@keyframes bounce {
  0% {
    transform: translateY(-4rem);
  }
  50% {
    transform: translateY(-3rem);
  }
  `;

import { useState } from 'react';

export const useDarkMode = () => {
  // hooks which tap into react's state. theme is the state variable within setTheme and we are passing the variable 'light' to setTheme to initiate
  // theme is then read within toggle theme and set to the new choice 
  const [theme, setTheme] = useState('light');
 // hooks which tap into react's state. componentMounts is the state variable within setComponentMounted and we are passing the variable false to setComponentMounted to initiate
  const [componentMounted] = useState(false);
  const setMode = mode => {
    window.localStorage.setItem('theme', mode)
    setTheme(mode)
  };

  const toggleTheme = () => {
    if (theme === 'light') {
      setMode('dark')
    } else {
      setMode('light')
    }
  };

  // checks to see if the user has already set preference for dark theme in their ios AND if they haven't already selected their theme preference in localStorage
  // useEffect(() => {
  //   const localTheme = window.localStorage.getItem('theme');
  //   window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && !localTheme ?
  //     setMode('dark') :
  //     localTheme ?
  //       setTheme(localTheme) :
  //       setMode('light');
  //   setComponentMounted(true);
  // }, []);

  return [theme, toggleTheme, componentMounted]
};
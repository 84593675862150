import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./projects.css";
import Header from "../Headers/Header";
import ReactMarkdown from "react-markdown";
import Slider from './Slider'

function LinkRenderer(props) {
  if(props.href.includes("youtube") && !props.href.includes("playlist")){
    let embed  = props.href.replace("watch?v=", "embed/")
  
    return (
      <div className="flex-row flex-center">
        <div><p>{props.children}</p></div>
       
    <iframe width="100%" height="315" src={embed} title="YouTube video player" className="video-player column-lg-8 column-md-10" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
  
    )
  }
  else if (props.href.includes("elevator.umn.edu")){
    if (props.href.includes("asset")){
      return (
        <div className="flex-row flex-center">
          <iframe height="480" width="100%" src={props.href} title="YouTube video player" className="video-player column-lg-10 column-md-12 column-sm-12" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
      )
    }
  else {
    return (
      <a href={props.href}>
    {props.children}
  </a>
    )
  }
  }
  else if (props.href.includes("storymap")){
    return (
      <div className="flex-row flex-center ">
     <iframe src={props.href} width="100%" height="500px" frameborder="0" allowFullScreen allow="geolocation"></iframe>
        </div>
    )
  }
 else if(props.href.includes("http"))
{
  return (
    <a href={props.href} target="_blank" rel=" noopener noreferrer">
      {props.children}
    </a>
  );
} else {
  return (
    <a href={props.href}>
    {props.children}
  </a>
  )
} 
}
function imageResizer(props){
  return (
    <div className="flex-row flex-center">
    <img src={props.src} alt={props.alt} className="resize"/>
    </div>

  )
}

function ProjectsPages() {
  const [project, setProject] = useState({});

  // get the slug of the URL we entered
  let { slug } = useParams();
  // then match it with our data.

  useEffect(() => {
    //get Tags
    const contentful = require("contentful");
    const client = contentful.createClient({
      space: "7urij08wib2s",
      accessToken: "RlThfQO4Z7KjHdZxPiO6bTvDDxBAwJX52q7E4qX5274",
    });

    // get Projects
    let projectsData = [];
    client
      .getEntries({ content_type: "project" })
      .then((response) => {
        projectsData = response.items;
        let currentProject = projectsData.filter(
          (currentProject) => currentProject.fields.slug === slug
        );
        // remove any trailing spaces
          currentProject[0].fields.slug = currentProject[0].fields.slug.replace(/\s/g, '');
          setProject(currentProject[0].fields);
      })
      .catch(console.error);
  }, []);

  return (
    <div>{project === undefined ? (<div><p>Page loading</p></div>):(<div>
      <div className="sub-billboard">
        {project.title === undefined ? (<div></div>):(<Header title={`Project: ${project.title}`} subtitle={``} home={false} />)}
       
      </div>

      <div className="wrapper padding-big">
        <div className="nav-item"></div>
        {project && (
          <div className="loose-page flex-row flex-center">
            <div className="column-sm-12 column-lg-10 column-md-10 project-section">
              <div className="flex-row flex-between">
                <div className="column-md-12 column-lg-12 column-sm-12">
                  {project.featuredPhoto !== undefined && (
                    <div className="flex-row flex-center">
                    <img
                      src={project.featuredPhoto.fields.file.url}
                      alt={project.featuredPhoto.fields.description}
                      className="smaller"
                    />
             
                    </div>
                  )}
                  <div className="flex-row flex-center photo-credit">
                       <ReactMarkdown components={{a:LinkRenderer}}>{project.mainPhotoCaption}</ReactMarkdown>
                        </div>
                </div>
                
                <div className="column-md-12 column-lg-12 column-sm-12 project-billboard flex-column start">
                  <h3>About the project</h3>
                  {project.tags !== undefined && (
                      <div className="flex-column end">
                        <div className="flex-row">
                          {" "}
                          {project.tags.map((tag, j) => {
                            return (
                              <div className="dark-tag" key={j}>
                                {tag.fields.label}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  <div className="project-description">
           
                    <ReactMarkdown components={{a:LinkRenderer, img: imageResizer}}>{project.longDescription}</ReactMarkdown>
                  </div>
                </div>
              </div>
              <div className="">
                {project.peopleDescription !==undefined && <h3>Partners and collaborators</h3>}
                <div className="project-description">
                  <ReactMarkdown components={{a:LinkRenderer, img: imageResizer}}>{project.peopleDescription}</ReactMarkdown>
                </div>
              </div>
              {project.examples !==undefined && <div className="">
                <div className="project-description">
                  <ReactMarkdown components={{a:LinkRenderer, img: imageResizer}}>{project.mediaDescription}</ReactMarkdown>
                </div>
                <div className="media-section">
                  <div className="wrapper flex-row flex-center">
                    {project.examples !== undefined && (
                      <div>
                          
                    <div className="flex-row media-section">
                     <div><Slider examples={project.examples}/></div>
                      </div>
                      </div>
                    )}
                  
                  </div>
                </div>
              </div>}
            </div>
          </div>
        )}
      </div>
    </div>)}

    </div>
    
  );
}
export default ProjectsPages;

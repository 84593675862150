import React from "react";
import "./404.css";
import Header from '../Headers/Header';

function fourOhFour() {
  return (
    <div className="page">
          <div className="sub-billboard">
        <Header
          title={null}
          subtitle={null}
          home={true}
          url={null}
          urlCTA={null}
        />

        <div className="site-title-container flex-column ">
          <div className="site-title">
          
          </div>
     
        </div>
      </div>
      <section className="section flex-row flex-center wrapper">
<div className="column-lg-6">
<h2>Whoops, nothing here.</h2>
      <p>Try going back <a href="/">home</a>.</p>
</div>
      

   
   
      </section>
 
    </div>
  );
}
export default fourOhFour;

import React, { useEffect, useState } from "react";
import Header from "../../Headers/Header";
import InternDetails from "./InternDetails";
import Select from "react-select";
import "./interns.css";

let filteredTerm = "";

function Interns(props) {
  const [interns, setInterns] = useState([]);
  const [featuredInterns, setFeatured] = useState([]);
  const [initials, setInitials] = useState([]);
  const [tags, setTags] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [setKeyword] = useState("");

  // get tags and initials for all interns
  function filterData(array) {
    let tagsArray = [];
    let initials = [];
    for (let i = 0; i < array.length; i++) {
      let index = initials.indexOf(array[i].fullName[0]);
      if (index === -1) {
        initials.push(array[i].fullName[0]);
      }
      if (array[i].linkedProjects) {
        for (let j = 0; j < array[i].linkedProjects.length; j++) {
          let tagIndex = tagsArray.indexOf(
            array[i].linkedProjects[j].fields.title
          );
          if (tagIndex === -1) {
            tagsArray.push(array[i].linkedProjects[j].fields.title);
          }
        }
      }
      if (array[i].linkedPartners) {
        for (let k = 0; k < array[i].linkedPartners.length; k++) {
          let tagIndex = tagsArray.indexOf(
            array[i].linkedPartners[k].fields.name
          );
          if (tagIndex === -1) {
            tagsArray.push(array[i].linkedPartners[k].fields.name);
          }
        }
      }
    }
    tagsArray.sort();
    tagsArray = tagsArray.map((str) => ({ value: str, label: str }));
    setTags(tagsArray);
    setInitials(initials);
  }

  function checkKeyword(keyword) {
    let filtered = [];
    let partnerProj = interns.filter((intern) => {
      return intern.linkedPartners;
    });
    let projProj = interns.filter((intern) => {
      return intern.linkedProjects;
    });
    partnerProj.filter((intern) => {
      for (let i = 0; i < intern.linkedPartners.length; i++) {
        if (intern.linkedPartners[i].fields.name === keyword) {
          filtered.push(intern);
        }
      }
    });
    projProj.filter((intern) => {
      for (let i = 0; i < intern.linkedProjects.length; i++) {
        if (intern.linkedProjects[i].fields.title === keyword) {
          filtered.push(intern);
        }
      }
    });
    setFiltered(filtered);
  }

  function setValue(value) {
    filteredTerm = value.value;
    setKeyword(filteredTerm);
    checkKeyword(filteredTerm);
  }
  function reset() {
    setKeyword("");
    setFiltered(interns);
  }

  useEffect(() => {
    const contentful = require("contentful");
    const client = contentful.createClient({
      space: "7urij08wib2s",
      accessToken: "RlThfQO4Z7KjHdZxPiO6bTvDDxBAwJX52q7E4qX5274",
    });

    // get interns

    client
      .getEntries({ content_type: "person" })
      .then((response) => {
        let peopleData = response.items;
        handlePeople(peopleData);
      })
      .catch(console.error);
    function handlePeople(array) {
      let people = [];
      for (let i = 0; i < array.length; i++) {
        if (array[i].fields.fullName === "Sebastian Alfonzo") {
          people.push(array[i].fields);
        } else if (array[i].fields.intern) {
          people.push(array[i].fields);
        }
      }
      people.sort((a, b) =>
        a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0
      );
      setInterns(people);
      setFiltered(people);
      filterData(people);
      let featured = people.filter((person) => person.featuredIntern === true);
      featured.sort((a, b) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
      );
      setFeatured(featured);
    }
  }, []);

  return (
    <div className="intern-page">
      <div className="sub-billboard">
        <Header
          title={`Meet Our Interns and Engaged Humanities Fellows`}
          subtitle={`Minnesota Transform interns work across community organizations and University departments doing social justice centered public humanities. Intern projects with MNT and our community partners include promoting the work of local arts and advocacy organizations, digital media work in communications and web design, exhibition development with local museums, and more. 
          Want to get involved? Check out our [open opportunities](http://localhost:3000/get-involved). `}
          url={null}
          urlCTA={null}
          home={false}
          slug={`about-us/interns-and-fellows`}
        />
      </div>

      <div className="intern-container">
        <h2>Minnesota Transform Intern Spotlight</h2>
        <p>Learn about some of our current interns.</p>
        <div id="highlights-interns">
          {featuredInterns.map((person, i) => {
            return (
              <InternDetails
                key={i}
                index={i}
                person={person}
                history={props.history}
              />
            );
          })}

          <div className="highlight-interns"></div>
        </div>
        <div>
          <h2 className="indent">Our Interns' Work</h2>
          <p className="indent">
            Our interns have worked with organizations across the Twin Cities
            and on numerous projects. Explore the scope of their work.
          </p>
          <div className="flex-row">
            {initials.map((initial, z) => {
              return (
                <span className="nav-key" key={z}>
                  <a href={`#${initial}`}>{initial}</a>
                </span>
              );
            })}
          </div>
          <div className="flex-row flex-center ">
            <div className="column-lg-12 light-filter-div">
              <div className="flex-row">
                <Select
                  options={tags}
                  value={filteredTerm}
                  onChange={setValue}
                  className="column-lg-6"
                />{" "}
                <button
                  onClick={reset}
                  className="small-button center column-lg-2 column-md-12 breathing-room"
                >
                  Reset
                </button>
                {/* to-do: add nav by letter of name  */}
                {/* to-do: only show 15 and show load more */}
              </div>
            </div>
          </div>
          <div>
            <div className="flex-row column-lg-12">
              {filtered.map((person, k) => {
                return (
                  <div
                    key={k}
                    className="intern-details-list-item flex-row flex-between column-lg-5"
                    id={person.fullName[0]}
                  >
                    <p className="color-block-1 column-lg-6 flex-column flex-center wrap-text">
                      {person.fullName}
                    </p>
                    <div className="flex-column flex-center column-lg-6">
                      {person.linkedPartners && (
                        <div className=" partners-div ">
                          {/* <h4 className="indent">Organization</h4> */}
                          <ul className="no-bullets flex-column flex-center">
                            {person.linkedPartners.map((partner, m) => {
                              if (partner.metadata) {
                                if (
                                  partner.fields.name !== "Minnesota Transform" && partner.fields.show === true
                                ) {
                                  return (
                                    <li className="project-item" key={m}>
                                      <a
                                        href={`/partners/${partner.fields.slug}`}
                                      >
                                        {partner.fields.name}
                                      </a>
                                    </li>
                                  );
                                } else if (partner.fields.name === "Minnesota Transform") {
                                  return (
                                    <li className="project-item" key={m}>
                                      <a href={`/`}>{partner.fields.name}</a>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li className="project-item" key={m}>
                                      {partner.fields.name}
                                    </li>
                                  );
                                }
                              }
                            })}
                          </ul>
                        </div>
                      )}
                      {person.linkedProjects && (
                        <div className="projects-div">
                          {/* <h4 className="indent">Project</h4> */}
                          <ul className="no-bullets flex-column flex-center">
                            {person.linkedProjects.map((project, l) => {
                              if (project.metadata){
                                if (project.fields.show === true) {
                                  return (
                                    <li className="project-item" key={l}>
                                      <a href={`/library/${project.fields.slug}`}>
                                        {project.fields.title}
                                      </a>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li className="project-item" key={l}>
                                      {project.fields.title}
                                    </li>
                                  );
                                }
                              }
                            
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Interns;

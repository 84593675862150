import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import logo from "../images/MNT_LOGO.png";
import "./header.css";

function SubpageHeader(props) {
  const [show, setVisibility] = useState(true);
  const [showPop, setShowPop] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [slug] = useState(props.slug);
  const [subMenuShow, setSubMenu] = useState(false);
  const [internsShow, setInterns] = useState(false);
 const [twitterShow, setTwitter]  = useState(false);
 const [instagramShow, setInstagram]  = useState(false);
 const [newsShow, setNews]  = useState(false);

 function LinkRenderer(props) {

  if(props.href.includes("http"))
{
  return (
    <a href={props.href} target="_blank" rel=" noopener noreferrer">
      {props.children}
    </a>
  );
} else {
  return (
    <a href={props.href}>
    {props.children}
  </a>
  )
} 
}
  function checkSize() {
    let w = document.documentElement.clientWidth;
    if (w <= 980) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }
  window.addEventListener("resize", checkSize);
  useEffect(() => {
    checkSize();
  }, []);

  function toggleMenu(event) {
    event.preventDefault();
    setInterns(false);
    setSubMenu(!subMenuShow);
 
  }
  function toggleInternsMenu(event) {
    event.preventDefault();
    setSubMenu(false);
    setInterns(!internsShow);
    
  }
  function handleClose (){
    setVisibility(true);
    setShowPop(false);
    console.log(`closing`)
  }
  function handleOpen () {
    setVisibility(false);
    setShowPop(true);
    console.log(`opening`)
  }
  return (
    <div className="site-header">
      <div className="cta-chip flex-column flex-center">
      <div className="flex-row end">
      <div>
          <a className="no-color-logo" href="/">
            <img src={logo} alt="MN Transform logo" className={`logo-subpage home-logo-${props.home}`} />
          </a>
        </div>
           <div className="social-menu">
            <div className="social-menu-inner flex-row end no-wrap  ">
            <div className="social-icon">
              <a className="chip"
                onMouseEnter={()=>setNews(true)} onMouseLeave={()=>setNews(false)}
                href="https://bit.us6.list-manage.com/subscribe?u=74ed66f0f2e8bde125c8ac8ad&id=38a24de226"
              >
                 {newsShow && <span className="chip-text" id="newsletter">Sign up for our newsletter!</span>}
                  <i className="fa-regular fa-paper-plane fa-lg" ></i>
              </a>
              </div>
              <div className="social-icon">
                <a className="chip" href="https://www.instagram.com/mn_transform/" onMouseEnter={()=>setInstagram(true)} onMouseLeave={()=>setInstagram(false)}>
                {instagramShow && <span className="chip-text" id="instagram">Instagram</span>}
                  <i className="fa-brands fa-instagram fa-lg"></i>
                </a>
              </div>
              <div className="social-icon">
                <a className="chip" href="https://twitter.com/mn_transform" onMouseEnter={()=>setTwitter(true)} onMouseLeave={()=>setTwitter(false)}>
               {twitterShow &&  <span className="chip-text" id="twitter">Twitter</span>}
                  <i className="fa-brands fa-twitter fa-lg"></i>
                </a>
              </div>
            </div>
       
           </div>
             
              {mobile &&
              <nav id="mobile-bars" className="social-icon flex-column flex-center">       
        <i
                    className={`fa-solid fa-bars fa-xl show-bars-${show}` }
                    onClick={() => handleOpen()}
                  ></i>
            
          
                  <nav id="site-navigation" className={`mobile-menu flex-row show-pop-out-${showPop} `}>
                    <div className="toolbar column-lg-12 flex-row end">
                      <i
                        className="fa-solid fa-xmark close-menu"
                        onClick={() => handleClose()}
                      ></i>
                    </div>
                    <div className="flex-column mobile-menu-list">
                    <a href="/" >
                        Home
                      </a>
                    <a
                  className={` ${
                    slug === "about-us" && "no-color-active"
                  }`}
                  href="/about-us"
                >
                  {" "}
                  <span>
                    About Us{" "}
                    {!internsShow ? (
                      <i
                        className="fa-solid fa-caret-down"
                        onClick={(event) => toggleInternsMenu(event)}
                      ></i>
                    ) : (
                      <i
                        className="fa-solid fa-caret-up"
                        onClick={(event) => toggleInternsMenu(event)}
                      ></i>
                    )}
                         {internsShow && (
                    <div className="flex-column sub-menu sub-menu-mobile">
                        <a className="no-color" href="/about-us">
                        Who We Are
                      </a>
                      <a className="no-color" href="/about-us/interns-and-fellows">
                        Interns and Fellows
                      </a>
                    </div>
                  )}
                  </span>
             
                </a>
                      <a href="/transforming-the-university">
                        Transforming the University
                      </a>
                      <a href="/library">Project Library</a>
                      <a href="/partners">Our Partners</a>
                      <a href="/acknowledgements">Acknowledgements</a>
                    </div>
                  </nav>
            
              </nav>}
            </div>
            {!mobile && <nav id="site-navigation" className="flex-row end">
                   <a
                  className={`no-color ${
                    slug === "about-us" && "no-color-active"
                  }`}
                  href="/about-us"
                >
                  {" "}
                  <span className="flex-column">
                    <span>About Us{" "}
                    {!internsShow ? (
                      <i
                        className="fa-solid fa-caret-down"
                        onClick={(event) => toggleInternsMenu(event)}
                      ></i>
                    ) : (
                      <i
                        className="fa-solid fa-caret-up"
                        onClick={(event) => toggleInternsMenu(event)}
                      ></i>
                    )}</span>
                          {internsShow && (
                    <div className="flex-column sub-menu">
                        <a className="no-color" href="/about-us">
                        Who We Are
                      </a>
                      <a className="no-color" href="/about-us/interns-and-fellows">
                        Interns and Fellows
                      </a>
                    </div>
                  )}
                  </span>
            
                </a>
                <a
                  className={`no-color ${
                    slug === "transforming-the-university" && "no-color-active"
                  }`}
                  href="/transforming-the-university"
                >
                  Transforming the University
                </a>
                <a
                  className={`no-color ${
                    slug === "library" && "no-color-active"
                  }`}
                  href="/library"
                >
                  Project Library
                </a>
                <a
                  className={`no-color ${
                    slug === "partners" && "no-color-active"
                  }`}
                  href="/partners"
                >
                 Our Partners
                </a>
                <a className={`no-color ${
                    slug === "acknowledgements" && "no-color-active"
                  }`} href="/acknowledgements">Acknowledgements</a>
              </nav>
            }
      </div>
     {mobile && <div>
          <a className="no-color-logo" href="/">
            <img src={logo} alt="MN Transform logo" className={`mobile-logo home-logo-${props.home}`} />
          </a>
        </div>}

      {props.title !== null && (
        <div className="sub-page-header">
          <div className="flex-column flex-center">
            <h1 className="blue">{props.title}</h1>
            <div className="subtitle flex-row flex-center">
              <ReactMarkdown className="column-lg-8" components={{a: LinkRenderer}}>
                {props.subtitle}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubpageHeader;
import React, { useEffect, useState } from "react";
import Header from "../Headers/Header";
import ReactMarkdown from "react-markdown";
import "./about.css";

function LinkRenderer(props) {
  if(props.href.includes("http"))
{
  return (
    <a href={props.href} target="_blank" rel=" noopener noreferrer">
      {props.children}
    </a>
  );
} else {
  return (
    <a href={props.href}>
    {props.children}
  </a>
  )
} 
}
function AboutUs() {
  const [faculty, setFaculty] = useState([]);
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    const contentful = require("contentful");
    const client = contentful.createClient({
      space: "7urij08wib2s",
      accessToken: "RlThfQO4Z7KjHdZxPiO6bTvDDxBAwJX52q7E4qX5274",
    });

    client
      .getEntries({ content_type: "person" })
      .then((response) => {
        let sorted = response.items.sort(
          (a, b) => a.fields.order - b.fields.order
        );
        let faculty = sorted.filter((item) => item.fields.faculty === true);
        let staff = sorted.filter((item) => item.fields.faculty === false && item.fields.intern === false);
        setFaculty(faculty);
        setStaff(staff);
      })
      .catch(console.error);

 
  }, []);
  return (
    <div className="about-page">
      <div className="sub-billboard">
      <Header
        title={`About Us`}
        subtitle={`Minnesota Transform's core team consists of University of Minnesota faculty and staff. We work in partnership with University departments and centers and community organizations. Our work is sponsored by the Andrew W. Mellon Just Futures Higher Education Initiative Grant.`}
        url={null}
        urlCTA={null}
        home={false}
        slug={`about-us`}
      
      />
      </div>
     
      <div className="wrapper">
        <div className="flex-row flex-center">
          <div className="column-lg-10 column-md-10" id="faculty">
            <h2 className="indent">Meet Our Faculty Coordinators</h2>
            <div className="flex-row faculty-container">
              {faculty.map((person, i) => {
                return (
                  <div
                    className="person-container flex-row column-lg-12 column-sm-12 column-md-12"
                    key={i}
                  >
                    <div className="headshot column-lg-5 column-md-5">
                      <img
                        src={`${person.fields.headshot.fields.file.url}?w=400`}
                        alt={person.fields.headshot.fields.description}
                      />
                      <h3>{person.fields.fullName}</h3>
                      <p>{person.fields.title}</p>
                    </div>
                    <div className="flex-column flex-center column-lg-6 faculty-bio">
                      <ReactMarkdown components={{a:LinkRenderer}}>{person.fields.bio}</ReactMarkdown>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex-row flex-center">
          <div className="column-lg-10 column-md-10 section" id="staff">
            <h2 className="indent">Meet Our Staff</h2>
            <div className="flex-row faculty-container">
              {staff.map((person, k) => {
                return (
                  <div
                    className="person-container flex-row column-lg-12 column-sm-12 column-md-12"
                    key={k}
                  >
                    <div className="headshot column-lg-5 column-md-5">
                      <img
                        src={`${person.fields.headshot.fields.file.url}?w=400`}
                        alt={person.fields.headshot.fields.description}
                      />
                      <h3>{person.fields.fullName}</h3>
                      <p>{person.fields.title}</p>
                    </div>
                    <div className="flex-column flex-center column-lg-6 faculty-bio">
                      <ReactMarkdown components={{a:LinkRenderer}}>{person.fields.bio}</ReactMarkdown>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="flex-row flex-center">
          <div className="column-lg-10 column-md-10 section" id="staff">
            <h2 className="indent">Past Contributors</h2>
            <div
                    className="person-container flex-row column-lg-12 column-sm-12 column-md-12"
                 
                  >
                    <div className="headshot column-lg-1">
                     
                    </div>
                    <div className="flex-column flex-center column-lg-11 faculty-bio">
                    
                     <p>David Chang</p>
                     <p>Sophie Hunt</p>
                     <p>Tadd Johnson</p>
                    </div>
                  </div>
          </div>
        </div>

     

    
    
      </div>
        
    </div>
  );
}
export default AboutUs;
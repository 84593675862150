import React, { useState, useEffect } from "react";
import "./projects.css";
import ProjectDetails from "./ProjectDetail";
import Select from "react-select";
import SubpageHeader from "../Headers/Header";

let filteredTerm = "";

function Projects() {
  const [projects, setProjects] = useState([]);
  const [tags, setTags] = useState([]);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    //get Tags
    const contentful = require("contentful");
    const client = contentful.createClient({
      space: "7urij08wib2s",
      accessToken: "RlThfQO4Z7KjHdZxPiO6bTvDDxBAwJX52q7E4qX5274",
    });

    // get Projects
    let projectsData = [];

    client
      .getEntries({ content_type: "project" })
      .then((response) => {
        projectsData = response.items;
        let active = projectsData.filter(
          (project) => project.fields.projectLibrary
        );
        handleProjects(active);
      })
      .catch(console.error);

    // parse out the stuff we don't need from contentful
    function handleProjects(array) {
      let content = [];
      client
        .getEntries({ content_type: "photos" })
        .then((response) => {
          content = response.items.filter(
            (item) => item.fields.page === "projects"
          );

          handleTags(array, content);
        })
        .catch(console.error);
    }
  }, []);

  function handleTags(projectsArray, photos) {
    let tags = [];
    for (let i = 0; i < projectsArray.length; i++) {
      projects.push(projectsArray[i].fields);
      for (let j = 0; j < projectsArray[i].fields.tags.length; j++) {
        tags.push(projectsArray[i].fields.tags[j].fields);
      }
      projects[i].tags = tags;
      tags = [];
    }

    let projectTags = projects.map((project) => project.tags);

    let tagsToAdd = [];
    for (let i = 0; i < projectTags.length; i++) {
      for (let j = 0; j < projectTags[i].length; j++) {
        let index = tagsToAdd.indexOf(projectTags[i][j]);
        if (index === -1) {
          tagsToAdd.push(projectTags[i][j]);
        }
      }
    }
    let shuffled = shuffleFisherYates(projects)
    setTags(tagsToAdd);
    combine(shuffled, photos);
  }

  function shuffleFisherYates(array) {
    let i = array.length;
    while (i--) {
      const ri = Math.floor(Math.random() * i);
      [array[i], array[ri]] = [array[ri], array[i]];
    }
    return array;
  }
  function combine(array, photosArray) {
  
    for (let i = 0; i < photosArray.length; i++) {
      let min = Math.ceil(0);
      let max = Math.floor(array.length - 1);
      let interval = Math.floor(Math.random() * (max - min) + min);
      array.splice(interval, 0, photosArray[i]);
    }
    setProjects(array);
  }
  const filterData = () => {
    let holderArray = [];
    for (let i = 0; i < projects.length; i++) {
      if (projects[i].tags){
        let filtered = projects[i].tags.filter(
          (tag) => tag.value.toLowerCase() === filteredTerm.value.toLowerCase()
        );
        if (filtered.length !== 0) {
          holderArray.push(projects[i]);
        }
      }
    }
    setFiltered(holderArray);
  };
  const setValue = (value) => {
    filteredTerm = value;
    filterData();
  };
  return (
    <div>
      <div className="sub-billboard">
        <SubpageHeader
          title={`Minnesota Transform Project Library`}
          subtitle={`Media, resources, and other projects created by Minnesota Transform
            and our partners.`}
          url={null}
          urlCTA={null}
          home={false}
        />
      </div>

      <section className=" no-margin" id="portfolio">
        {/* filter results */}
        <div className="flex-row flex-center ">
          <div className="column-md-6 column-lg-6 light-filter-div">
            <div className="flex-row flex-evenly">
              <Select
                options={tags}
                value={filteredTerm}
                onChange={setValue}
                className="column-lg-9 column-md-9"
              />
              <button
                onClick={() => setValue({ value: " ", label: " " })}
                className="small-button center column-lg-2 column-md-2 breathing-room"
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        {filtered.length > 0 ? (
          <div className="flex-row flex-center">
            {filtered.map((item, i) => {
              if (item !== undefined){
                return <ProjectDetails project={item} key={i} />;
              } else {
                return <div></div>
              }
             
            })}
          </div>
        ) : (
          <div className="flex-row flex-center">
            {projects.map((item, i) => {
              if (item !== undefined){
                return <ProjectDetails project={item} key={i} />;
              } else {
                return <div></div>
              }
             
            })}
          </div>
        )}
      </section>
    </div>
  );
}
export default Projects;

import React, { useEffect, useState } from "react";
import Header from "../Headers/Header";
import "./mission.css";
import MissionDetails from "./MissionDetails";

function Mission(props) {
  const [sections, setSections] = useState([]);

  useEffect(() => {
      const contentful = require("contentful");
      const client = contentful.createClient({
        space: "7urij08wib2s",
        accessToken: "RlThfQO4Z7KjHdZxPiO6bTvDDxBAwJX52q7E4qX5274",
      });
      client
        .getEntries({ content_type: "transformingSection" })
        .then((response) => {
          let data = response.items;
          data = data.sort((a, b) => a.fields.order - b.fields.order);
          setSections(data);
        })
        .catch(console.error);
  }, []);

  return (
    <div className="mission">
      <div className="sub-billboard">
        <Header
          title={`Transforming the University`}
          home={false}
          url={null}
          urlCTA={null}
          subtitle={`Minnesota Transform reckons with histories of violence committed by
          the University of Minnesota against Tribal Nations and communities
          of color in Minnesota.`}
          slug={`transforming-the-university`}
        />
      </div>

      <div className="wrapper flex-row flex-center">
  

        <section className="indent">
          <h2>What we're doing about it</h2>
          <p>
            By producing and supporting non-traditional knowledges,
            redistributing University wealth and resources, and creating
            equitable opportunities for students, MN Transform aims to change
            the University from within. Read more about projects and
            collaborations we're working on to transform the University.
          </p>
          <div className="flex-row">
            {sections.map((section, i) => {
              return <MissionDetails key={i} section={section} />;
            })}
          </div>
        </section>
      </div>
    </div>
  );
}
export default Mission;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./partners.css";
import Header from "../Headers/Header";
import ReactMarkdown from "react-markdown";

function LinkRenderer(props) {
  if(props.href.includes("http"))
{
  return (
    <a href={props.href} target="_blank" rel=" noopener noreferrer">
      {props.children}
    </a>
  );
} else {
  return (
    <a href={props.href}>
    {props.children}
  </a>
  )
} 
}
function PartnerPages() {
  const [partner, setPartner] = useState({});

  // get the slug of the URL we entered
  let { slug } = useParams();
  // then match it with our data.

  useEffect(() => {
    //get Tags

    const contentful = require("contentful");
    const client = contentful.createClient({
      space: "7urij08wib2s",
      accessToken: "RlThfQO4Z7KjHdZxPiO6bTvDDxBAwJX52q7E4qX5274",
    });

    // get partner
    let partnerData = [];
    client
      .getEntries({ content_type: "partner" })
      .then((response) => {
        partnerData = response.items;
        let currentpartner = partnerData.filter(
          (currentpartner) => currentpartner.fields.slug === slug
        );
        currentpartner[0].fields.slug = currentpartner[0].fields.slug.replace(/\s/g, '');
        setPartner(currentpartner[0].fields);
      })
      .catch(console.error);
  }, []);

  return (
    <div>
      <div className="sub-billboard">
        <Header
          title={partner.name}
          subtitle={partner.shortDescription}
          home={false}
        />
      </div>

      <div className="wrapper">
        <div className="nav-item"></div>
        <div className="padding-big">
        {partner && (
          <div className="loose-page flex-row flex-center">
            <div className="column-sm-12 column-lg-12 column-md-12 partner-section">
              <div className="flex-row flex-evenly">
                <div className="column-md-5 column-lg-5 column-sm-12">
               
                  {partner.featuredImage !== undefined && (
                    <img
                      src={partner.featuredImage.fields.file.url}
                      alt={partner.featuredImage.fields.description}
                      className="full-width bump-h3 frame"
                    />
                  )}
              </div>
             
                <div className="column-md-6 column-lg-6 column-sm-12 partner-billboard flex-column start">
                  <h3>About</h3>
                  <div className="partner-description">
                    <ReactMarkdown components={{a:LinkRenderer}}>{partner.narrative}</ReactMarkdown>
                  </div>
                  {partner.website && <div><h3>Visit their website</h3><a href={partner.website} target="_blank" rel="noopener noreferrer">{partner.name}</a></div>}
                </div>
              </div>
              {partner.linkedProjects && <div className="column-sm-12 column-lg-12 column-md-12" id="partner-projects"
              ><h3>Projects</h3>
              <div className="flex-row">
              {partner.linkedProjects.map((ex, i) => {
                return(
                  <div className="project-card column-sm-12 column-lg-5">
                    <img src={ex.fields.featuredPhoto.fields.file.url} alt={ex.fields.featuredPhoto.fields.file.description} className="full-width"/>
                   <a href={`/library/${ex.fields.slug}`} className="no-decoration"><h4>{ex.fields.title}</h4></a>
                    </div>
                )
              })}
              </div>
        
              </div>}
            </div>
          </div>
        )}
                </div>
       
      </div>
    </div>
  );
}
export default PartnerPages;

import React, { useState, useEffect } from "react";
import "./partners.css";
import PartnerInfo from "./PartnerInfo";
import Header from "../Headers/Header";
import Select from "react-select";

let filteredTerm = "";

function Partners() {
  const [partners, setPartners] = useState([]);
  const [tags, setTags] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [message, setMessage] = useState("");
  

  useEffect(() => {
    const contentful = require("contentful");
    const client = contentful.createClient({
      space: "7urij08wib2s",
      accessToken: "RlThfQO4Z7KjHdZxPiO6bTvDDxBAwJX52q7E4qX5274",
    });

    client
      .getEntries({ content_type: "partner" })
      .then((response) => {
        let sorted = response.items.sort((a, b) =>
          a.fields.name > b.fields.name ? 1 : -1
        );
        const index = sorted.findIndex((object) => {
          return object.fields.name === "Minnesota Transform";
        });
        sorted.splice(index, 1);
        const results = sorted.filter((obj) => {
          return obj.fields.show === true;
        });
        let partnerTags = sorted.map((partner) => partner.fields.tags);
        let tagsToAdd = [];
        for (let i = 0; i < partnerTags.length; i++) {
          for (let j = 0; j < partnerTags[i].length; j++) {
            let index = tagsToAdd.indexOf(partnerTags[i][j].fields);
            if (index === -1) {
              tagsToAdd.push(partnerTags[i][j].fields);
            }
          }
        }
        let sortedTags = tagsToAdd.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        );
        setPartners(results);
        setTags(tagsToAdd);
      })
      .catch(console.error);
  }, []);

  const multiFilter = (tags) => {
    let filtered = []
    // we have an array of keywords we want to use to filter our list of partners down
    // the list of partners contains a sublist of tags which are our matching criteria
    let simpleTags = tags.map(tag => tag.value.toLowerCase());
    simpleTags.sort()
    partners.filter((partner) => {
      if(partner.fields.tags.length < simpleTags.length){
        return; 
      } else {
        let partnerTags = partner.fields.tags.map((tag => tag.fields.value.toLowerCase()));
        partnerTags.sort()
        // every element in the simple tags array is present in the partner tags array
        let include = simpleTags.every(element => partnerTags.includes(element));
       if (include){
        filtered.push(partner)
       }
      }
    })
      setFiltered(filtered);
      if (filtered.length === 0 ){
        setMessage('No results. Try broadening your parameters.')
      }
  }
 

  const setValue = (value) => {
    filteredTerm = value;
    multiFilter(filteredTerm)
  };
  return (
    <div>
      <div className="sub-billboard">
        <Header
          title={`Our Partners`}
          subtitle={`We are proud to work with tribal and higher education
  institutions, arts and community organizations, and University of
  Minnesota departments and centers. Our work is [sponsored](#sponsors) by the Andrew W. Mellon Just Futures Higher Education Initiative Grant. Visit our [Community News and Events Archive](/community-news-archive) to learn more about our community's past happenings.`}
          url={null}
          urlCTA={null}
          home={false}
          slug={`about-us`}
        />
      </div>

      <div className="wrapper margin-all">
      <iframe src="https://storymaps.arcgis.com/stories/63f9da73200747478544aa72ad0704e1" width="100%" height="600px" frameborder="0" allowfullscreen allow="geolocation"></iframe>
      </div>
      <div id="partners">
  
        <div className="wrapper">
          <div className="flex-row flex-center ">
            <div className="column-md-6 column-lg-6 filter-div">
              <div className="flex-row flex-between">
                <label htmlFor="keyword-filters" className="white-text">
                  Filter by:{" "}
                </label>
             
              </div>

              <br />
           
              <Select isMulti options={tags}  defaultValue={[]} onChange={setValue}/>
            </div>
          </div>
        </div>

        {filtered.length > 0 ? (
          <div className="flex-row flex-center">
            <div className="column-lg-10 column-md-10 indent section">
              <div className="flex-row flex-center">
                {filtered.map((partner, j) => {
                  return <PartnerInfo partner={partner} key={j} />;
                })}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-row flex-center">
            <div className="column-lg-10 column-md-10 indent section">
              {message.length > 0 ? (<div className="flex-row flex-center"><p>{message}</p></div>):( <div className="flex-row flex-center">
                {partners.map((partner, j) => {
                  return <PartnerInfo partner={partner} key={j} />;
                })}
              </div>)}
           
            </div>
          </div>
        )}
      </div>

      <div className="flex-row flex-center">
        <div className="column-lg-10 column-md-10 indent section" id="sponsors">
          <h2>
            An Andrew W. Mellon Just Futures Higher Education Initiative Grant
          </h2>
          <p>
            In 2020, the{" "}
            <a
              href="https://mellon.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Andrew W. Mellon Foundation’s
            </a>{" "}
            Higher Learning Program awarded 16 projects under the{" "}
            <a
              href="https://mellon.org/initiatives/just-futures/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Just Futures initiative
            </a>{" "}
            to support multidisciplinary and multi-institutional collaborative
            teams producing humanities-based work to address racial inequity and
            lead to the building of socially just futures.
          </p>{" "}
          <p>
            Read the{" "}
            <a
              href="https://ias.umn.edu/programs/public-scholarship/minnesota-transform"
              target="_blank"
              rel="noopener noreferrer"
            >
              Minnesota Transform Mellon Foundation Just Futures press release
            </a>{" "}
            from the Institute for Advanced Study.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Partners;

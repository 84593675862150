import React, { useEffect, useState } from "react";
import Header from '../Headers/Header';
import ContentDetails from "./ContentDetails";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import './news.css';
import ShowcaseItem from './ShowcaseItem';


function NewsUpdates() {
    const [content, setContent ] = useState([]);
    const [ internalJobs, setInternal ] = useState([]);
    const [ photos, setPhotos ] = useState([]);
    const [ showcaseText, setShowcaseText] = useState([]);
    const [ showcase, setShowcase ] = useState([]);
    const [ displayShowcase, setDisplay ] = useState(true)
    const today = new Date().toISOString();
    const contentful = require("contentful");
    const client = contentful.createClient({
      space: "7urij08wib2s",
      accessToken: "RlThfQO4Z7KjHdZxPiO6bTvDDxBAwJX52q7E4qX5274",
    });

  useEffect(() => {
    client
    .getEntries({ content_type: "photos" })
    .then((response) => {
      let content = response.items.filter((item => item.fields.page === "news"));
      setPhotos(content);
      getNews(content);

    })
    .catch(console.error);
  }, []);

  function getNews(photosArray){
    //get all news content
    client
    .getEntries({ content_type: "news" })
    .then((response) => {
      let content = response.items;
      // pull out the showcase series items
    let showcase = content.filter(item => item.fields.showcase);
    setShowcase(showcase);
     let normalContent = content.filter(item=>item.fields.showcase===false || item.fields.showcase === undefined);
     console.log(normalContent)
      //check if internal
      getInternalJobs(normalContent, photosArray);
    })
    .catch(console.error);
      }

function getInternalJobs (array, photosArray){
  let internal = array.filter(job => job.fields.internalJob);
  setInternal(internal);
  // check what things are archived vs. active
  let external = array.filter(job => job.fields.internalJob === false);
  checkDate(external, photosArray);
}

let archiveArray = [];

  function checkDate(array, photosArray){
    let current = array.filter(( item => !archiveArray.includes(item)));
   combine(current, photosArray);
  }

  function combine(array, photosArray){
    for (let i = 0; i < photosArray.length; i++){
    let min = Math.ceil(0);
      let max = Math.floor(array.length-1);
      let interval = Math.floor(Math.random() * (max - min) + min);
      array.splice(interval,0,photosArray[i]);
    }
    setContent(array);
  }
  return (
    <div className="about-page">
    <div className="sub-billboard">
    <Header
      title={`Community News and Events Archive`}
      subtitle={`From 2021-2023, we supported projects that addressed transformational decolonial and racial justice in the University, Twin Cities, and state through public humanities projects. Check out some of our past community events, news, and opportunties.`}
      url={null}
      urlCTA={null}
      home={false}
      slug={`get-involved`}
    />
     
    </div>
 
    <div className="wrapper-sm">
      {displayShowcase && <div id="showcase-section" className="flex-row flex-center">
       
        <div className="left-text">
        <h3 className="indent">Minnesota Transform Showcase</h3>
      <p className="indent">The MN Transform Showcase Series was a week-long series in April 2023 consisting of 10 events which celebrated the phenomenal projects of our community and campus partners. Events demonstrated how community partnerships help build a future where racial justice is sustained and embedded in the University.</p>
        </div>
      <div className="column-lg-12 column-md-12 section indent">
     <ReactMarkdown>{showcaseText}</ReactMarkdown>
     <div className="flex-row flex-center">
      {showcase.map((item, i) => {
        return(
          <ShowcaseItem item={item} key={i} full={false}/>
        )
      })}
     </div>
       </div>
      </div>}
    <div className="flex-row flex-center">
        <div className="column-lg-12 column-md-12 section">
          <h3 id="internal" className="indent">Bulletin Board</h3>
          <p className="indent">Past news, events, and opportunities in our community.</p>
            <div className="flex-row flex-center">
            {internalJobs.map((item, i) => {
                    return (
                        <ContentDetails item={item} key={i} full={false}/>
                     )
            })}
                {content.map((item, i) => {
                    return (
                        <ContentDetails item={item} key={i} full={false}/>
                     )
            })}
            </div>
    
            </div>
        </div>

    
    </div>
</div>
  )
      
}

export default NewsUpdates;

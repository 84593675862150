// theme.js
export const lightTheme = {
    name: 'light',
    body: '#f8f8ff',
    text: '#363537',
    toggleBorder: '#841525',
    gradient: 'linear-gradient(#ffe270, #CEBACF)',
    containerBody: 'rgba(248,248,255, 80%)',
    containerText: '#2D2D2A',
    containerHighlight: '#000000', 
    containerHighlightText: 'white',
    link: '#02213B',
    shadow: '#5BC0BE',
    selection: 'rgba(0, 0, 0, .8)',
    highlight: '#ffd73d',
    secondary: '#a8daec',
    icon_color: '#363537', 
    color_body: '#363537',
    background_image: '../components/images/waves.svg'
  }
  
  export const darkTheme = {
    name: 'dark',
    body: '#363537',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    gradient: 'linear-gradient(#CEBACF, #7D80DA)',
    containerBody: '#474649',
    containerText: '#EEE5E9',
    containerHighlight: '#EA7AF4',
    containerHighlightText: 'black',
    link: '#DAFF7D',
    shadow: '#EA7AF4',
    selection: '#FFF',
    highlight: '#EA7AF4', 
    secondary: '#D0E37F',
    icon_color: 'transparent', 
    color_body: '#811324',
    background_image: '../components/images/waves.svg'
  }
import React, { useEffect, useState } from "react";
import Header from "../Headers/Header";
import ReactMarkdown from "react-markdown";

function LinkRenderer(props) {
  if (props.href.includes("http")) {
    return (
      <a href={props.href} target="_blank" rel=" noopener noreferrer">
        {props.children}
      </a>
    );
  } else {
    return <a href={props.href}>{props.children}</a>;
  }
}
function Acknowledgements() {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const contentful = require("contentful");
    const client = contentful.createClient({
      space: "7urij08wib2s",
      accessToken: "RlThfQO4Z7KjHdZxPiO6bTvDDxBAwJX52q7E4qX5274",
    });

    client
      .getEntries({ content_type: "partner" })
      .then((response) => {
        let sorted = response.items.sort((a, b) =>
          a.fields.name > b.fields.name ? 1 : -1
        );
        const index = sorted.findIndex((object) => {
          return object.fields.name === "Minnesota Transform";
        });
        sorted.splice(index, 1);
        addPartners(sorted);
      })
      .catch(console.error);
  }, []);
  function addPartners(array) {
    console.log(array);
    let newData = [
      {
        fields: {
          website: "https://humantoll35w.org/",
          name: "A Public History of 35W - Dr. Ernest Lloyd and Dr. Greg Donofrio",
        },
      },
      {
        fields: {
          website: "https://aliveness.org/",
          name: "Aliveness Project",
        },
      },
      {
        fields: {
          website:
            "https://storymaps.arcgis.com/stories/20d34f8e043e4b3ea1f8f20bdb4ebdce",
          name: "Cap Wigington Project",
        },
      },
      {
        fields: {
          website: "/library/dakota-language-audio-journal",
          name: "Dakota Language Project",
        },
      },
      {
        fields: {
          website: "https://georgefloydglobalmemorial.org/the-team/jeanelle-austin/",
          name: "George Floyd Global Memorial and Jeanelle Austin",
        },
      },
      {
        fields: {
          website: undefined,
          name: 'Hawona Sullivan Janzen'
        }
      },
      {fields: {website: 'https://hennepinhistory.org/', name:'Hennepin History Museum'}},
      {fields: {website: 'https://www.inspire-to-change.org/', name: "Inspire to Change"}},
      {fields: {website: 'https://ladonnasandersredmond.com/', name: 'Ladonna Redmond'}},
      {fields: {website: 'https://www.mnhs.org/', name: 'Minnesota Historical Society'}},
      {fields: {website: 'https://mn.gov/indian-affairs/', name: 'Minnesota Indian Affairs Council'}},
      {fields: {website: 'https://northeast.mpschools.org/', name: 'Northeast Middle School'}},
      {fields: {website: 'https://www.designandagency.com/olivia-house', name: 'Olivia House Design'}},
      {fields: {website: undefined, name: 'Renewal Project - Dr. Davarian Baldwin'}},
      {fields: {website: 'https://sabathani.org/', name: 'Sabathani Community Center'}},
      {fields: {website: 'https://hennepinhistory.org/separate-not-equal/', name: 'Separate Not Equal - The Hale-Field Pairing'}},
      {fields: {website: 'https://www.ananyadancetheatre.org/2018/08/shawngram-institute-for-performance-social-justice/', name: 'Shawngram Institute for Performance & Social Justice'}},
      {fields: {website: 'https://www.swcollege.edu/newswcollege/', name: 'Sisseton Wahpeton College'}},
      {fields: {website: 'http://www.swodli.com', name: 'Sisseton-Wahpeton Oyate Dakotah Language Institutes '}},
      {fields: {website: 'https://www.soomaalhouse.com/', name: 'Soomaal House of Art'}},
      {fields: {webiste: 'https://sites.google.com/view/truthproject', name: 'TRUTH Project'}},
      {fields: {website: undefined, name: 'Upper Sioux Community'}},
      {fields: {website: 'https://www.migizi.org/', name: 'MIGZI'}},
      {fields: {website: 'https://www.spps.org/', name: 'St. Paul Public Schools'}},
      {fields: {website: 'https://www.mpschools.org/', name: 'Minneapolis Public Schools'}},
      {fields: {website: 'https://cla.umn.edu/ais/', name: 'Department of American Indian Studies'}},
      {fields: {website: 'https://cla.umn.edu/ais/news-events/events/whose-campus-history-workshop-mapping-campus-history', name: 'Campus Mapping Project'}},
      {fields: {website: 'https://cei.umn.edu/', name: 'Center for Educational Innovation'}},
      {fields: {website: 'https://cla.umn.edu/', name: 'College of Liberal Arts'}},
      {fields: {website: undefined, name: 'Dean John Coleman'}},
      {fields: {website: 'https://cla.umn.edu/about/directory/profile/lindqust', name: 'Associate Dean Malinda Lindquist'}},
      {fields: {website: 'https://cla.umn.edu/about/directory/profile/meierann', name: 'Associate Dean Anne Meier'}},
      {fields: {website: undefined, name: 'Mackenzie Sullivan'}},
      {fields: {website: '/library/covid-stories', name: 'COVID Stories'}},
      {fields: {website: 'https://cdsc.umn.edu/', name: 'Critical Disability Studies Collective'}},
      {fields: {website: 'https://design.umn.edu/news/2021-heritage-studies-and-public-history-capstone-presentations', name: 'De La Clase Trabajadora'}},
      {fields: {website: undefined, name: 'Decolonizing Map Project - Charles Golding'}},
      {fields: {website: 'https://cla.umn.edu/american-studies', name: 'Department of American Studies'}},
      {fields: {website: 'https://cla.umn.edu/chicano-latino', name: 'Department of Chicano and Latino Studies'}},
      {fields: {website: 'https://cla.umn.edu/history', name: 'Department of History'}},
      {fields: {website: 'https://cei.umn.edu/programs/teaching-access-and-inclusion-program', name: 'Teaching with Access and Inclusion Program'}},
      {fields: {website: 'https://disability.umn.edu/', name: 'Disability Resource Center'}},
      {fields: {website: 'https://www.bbqplus.org/people/caitlin-gunn', name: 'Dr. Caitlin Gunn'}},
      {fields: {website: 'https://cla.umn.edu/community-employer-partners/community-engagement/liberal-arts-engagement-hub', name: 'Liberal Arts Engagement Hub'}},
      {fields: {website: 'https://grad.umn.edu/', name: 'The Graduate School'}},
      {fields: {website: undefined, name: 'Dean Scott Lanyon'}},
      {fields: {website: undefined, name: 'Noro Andriamanalina'}},
      {fields: {website: 'https://olpd.umn.edu/cori-bazemore-james', name: 'Cori Bazemore-James'}},
      {fields: {website: '/library/experiences-of-multiply-marginalized-disabled-umn-graduate-students', name: 'GRUDGE'}},
      {fields: {website: 'https://cla.umn.edu/gwss', name: 'Department of Gender, Women, and Sexuality Studies'}},
      {fields: {website: 'https://libnews.umn.edu/2022/01/history-for-the-future-runs-through-may-11/', name: 'History for the Future'}},
      {fields: {website: 'https://imaginefund.umn.edu/', name: 'The Imagine Fund'}},
      {fields: {website: undefined, name: 'Indigenous Oral History Lab'}},
      {fields: {website: 'https://mappingprejudice.umn.edu/', name: 'Mapping Prejudice'}},
      {fields: {website: 'https://www.mplspanorama.com/about', name: 'Minneapolis Panorama'}},
      {fields: {website: undefined, name: 'Mutual Aid Project - Amber Delgado, Mohamud Mohamed, and Treasure Tinsely'}},
      {fields: {website: 'https://diversity.umn.edu/', name: 'Office for Equity and Diversity'}},
      {fields: {website: 'https://learning.umn.edu/public/category/programArea.do?method=load&selectedProgramAreaId=10793712', name: 'Office for Public Engagement'}},
      {fields: {website: 'https://housing.umn.edu/llcs/ojibwewigamig', name: 'Ojibwe Immersion House'}},
      {fields: {website: 'https://ojibwe.lib.umn.edu/', name: `Ojibwe People's Dictionary`}},
      {fields: {website: 'https://design.umn.edu/directory/terresa-moses', name: `Dr. Terresa Moses`}},
      {fields: {website: 'https://cla.umn.edu/theatre', name: `Department of Theatre Arts and Dance`}},
      {fields: {website: 'https://www.cehd.umn.edu/trio/services/', name: 'TRIO Student Support Services'}},
      {fields: {website: 'https://lab-school.umn.edu/', name: 'Child Development Labratory School'}},
      {fields: {website: 'https://law.umn.edu/', name: 'Universtity of Minnesota Law School'}},
      {fields: {website: 'https://www.upress.umn.edu/', name: 'Universtity of Minnesota Press'}},
      {fields: {website: 'https://hsl.lib.umn.edu/wangensteen', name: 'Wangensteen Historical Library'}},
      {fields: {website: 'https://cla.umn.edu/asianamerican', name: 'Asian American Studies Program'}}
    ];
    let totalData = array.concat(newData);
    totalData.sort((a, b) => {
      // console.log(a.fields.name,b.fields.name)
      const nameA = a.fields.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.fields.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    setPartners(totalData);
  }
  return (
    <div className="about-page">
      <div className="sub-billboard">
        <Header
          title={`Acknowledgements`}
          subtitle={``}
          url={null}
          urlCTA={null}
          home={false}
          slug={`acknowledgements`}
        />
      </div>

      <div className="wrapper">
        <div className="flex-row flex-center">
          <div className="column-lg-10 column-md-10" id="faculty">
            <p>
              We would like to express our sincere appreciation for the
              invaluable knowledge and extensive support provided to MN
              Transform by the Institute for Advanced Study over the past three
              years. The contributions and assistance from the IAS staff have
              been instrumental in making our endeavors possible. Thank you to
              the individuals whose steadfastness, creativity, and commitment
              have been crucial: Susannah Smith, Juliet Burba, Skyler Dorr, Abby
              Travis, Carolina Maranon-Cobos, Jennifer Gunn, and Bianet
              Castellanos.
            </p>
            <p>
              This project took enormous commitment from many UMN staff, past
              and present. We particularly want to thank LuGail Hamel, Lori
              Young-Williams, and Wesley Ballinger.
            </p>
            <p>
              Thank you to the Mellon Foundation for the vision of universities
              as sites of racial justice. Their support and flexibility have
              been crucial to our work over the past few years, and
              inspirational as we face the future.
            </p>
            <p>
              All of our{" "}
              <a href="about-us/interns-and-fellows">
                interns, graduate students, and community fellows
              </a>
              .
            </p>
            <p>And our remarkable community and University partners:</p>
            <ul>
              {partners.map((partner, i) =>
                partner.fields.meta !== undefined ? (
                  <div key={i}>
                    <li>
                      <a href={partner.fields.website}>{partner.fields.name}</a>
                      <div className="project-card no-left">
                      <ReactMarkdown components={{a:LinkRenderer}}>{partner.fields.meta}</ReactMarkdown>
                      </div>
                     
                    </li>
                  </div>
                ) : (
                  <div key={i}>
                    <li>
                      <a href={partner.fields.website}>{partner.fields.name}</a>
                    </li>
                  </div>
                )
              )}
            </ul>

            <p>
              <strong>MNT staff and leaders:</strong>
            </p>
            <ul>
              <li>Sebastian Alfonzo</li>
              <li>Dr. Joseph Bauerkemper</li>
              <li>Jasmine Baxter</li>
              <li>Dr. Angela Carter</li>
              <li>Dr. David Chang</li>
              <li>Dr. Jigna Desai</li>
              <li>Dr. Tracey Deutsch</li>
              <li>Dr. Michelle Garvey</li>
              <li>Dr. Sophie Hunt</li>
              <li>Dr. Tadd Johnson</li>
              <li>Erica Lee</li>
              <li>Andrea Manolov</li>
              <li>Dr. Kevin Murphy</li>
              <li>Denise Pike</li>
              <li>Kristen Reynolds</li>
              <li>Dr. Kari Smalkoski</li>
              <li>Dr. Ricardo Velasco Trujillo</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Acknowledgements;

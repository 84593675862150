import React, { useEffect, useState } from "react";

function FeaturedProjectDetail(props) {
  const carousel = props.project.carousel;
  let [currentImage, setCurrentImage] = useState({image: '', alt: ''});
  let [index, iterate] = useState(0);
  const [visible, setVisibility] = useState(true);
  const [moreVisible, setMore ] = useState(false)

  useEffect(() => {
    if (props.project.carousel !== undefined){
      setCurrentImage({image: carousel[0].fields.file.url, alt: carousel[0].fields.description})
    } else {
      setCurrentImage({image: props.project.highlightPhoto.fields.file.url, alt: props.project.highlightPhoto.fields.description})
    }
  }, []);

 function moveThrough(){
   if(index === carousel.length - 1){
    setCurrentImage({image: carousel[0].fields.file.url, alt: carousel[0].fields.description});
    iterate(0)
   } else if (index > carousel.length) {
    setCurrentImage({image: carousel[0].fields.file.url, alt: carousel[0].fields.description});
    iterate(0)
   } else {
    setCurrentImage({image: carousel[index+1].fields.file.url, alt: carousel[index+1].fields.description});
    iterate(index+1);
   }
 }
  function reset(){
    setVisibility(false);
    setMore(false);
  }
  return (
    <div className={`gallery__item gallery__item--${props.index}` } > 
     {/* to add later to this element:  onClick={()=>props.history.push(`library/${props.project.slug}`)} */}
     <div className="highlight-title flex-row flex-wrap flex-between">{!visible ?(<h3><i
  className="fa-solid fa-plus fa-xs"
  onClick={() => {
    setVisibility(true);
  }}
></i></h3>):(<h3>{props.project.title} {!moreVisible ?(<i className="fa-solid fa-circle-chevron-down" onClick={()=>setMore(true)}></i>):(<i className="fa-solid fa-minus fa-xs" onClick={()=>reset()}></i>)}</h3>)}

{moreVisible &&<div className="flex-row end">
  <p className="column-lg-12">{props.project.shortDescription}</p><a href={`/library/${props.project.slug}`}><button>Learn more</button></a></div>}

     </div>
      {!moreVisible ?(<img
          src={currentImage.image}
          alt={currentImage.alt}
          className={`gallery__img`}
        />):(<img
          src={currentImage.image}
          alt={currentImage.alt}
          className={`gallery__img grayed`}
        />)}
  {carousel !== undefined && <div className="flex-row flex-between carousel-icons">
    <div className="icon-container"> 

  <i className="fa-solid fa-angles-right fa-xl nav-icon" onClick={()=>moveThrough()}></i>
  </div>
  </div>}
 
    </div>
  );
}

export default FeaturedProjectDetail;

import React from "react";

function ProjectDetails(props) {

  return (
    <div className="project-container column-lg-3 column-md-5">
      <div className="text-container">
        <div className="color-bar"></div>
        <div className="description">
          <h3 className="title-only">{props.project.title}</h3>
        
          {props.project.featuredPhoto && 
          <div className="flex-row flex-center">
          <img src={props.project.featuredPhoto.fields.file.url} alt={props.project.featuredPhoto.fields.file.description} className="column-lg-12 column-md-12 card-photo"/>
          </div>}
         {props.project.tags && <div className="flex-row"> 
          {props.project.tags.map((tag, i) => {
            return (<div className="dark-tag" key={i}>{tag.label}</div>)
          })}
          </div>}    
          </div>    
       <div className="flex-rox flex-center">
              <button className="centered-padding"> <a className="link" href={`/library/${props.project.slug}`}>Explore  &rarr;</a></button>
       </div>

      </div>
    </div>
  );
}
export default ProjectDetails;

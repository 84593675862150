import React, { useState } from "react";
import ReactMarkdown from "react-markdown";

function LinkRenderer(props) {
  if(props.href.includes("http"))
{
  return (
    <a href={props.href} target="_blank" rel=" noopener noreferrer">
      {props.children}
    </a>
  );
} else {
  return (
    <a href={props.href}>
    {props.children}
  </a>
  )
} 
}
function MissionDetails(props) {
  const [visible, setVisibility] = useState(false);
  return (
    <div className="example-section">
      <div className="flex-row evenly">
        <img
          src={props.section.fields.icon.fields.file.url}
          alt={props.section.fields.icon.fields.description}
          className="example-photo column-lg-4 column-md-3"
        />
       
        <h3 className="example-title column-lg-6 column-md-8">
          {props.section.fields.sectionTitle}
        </h3>
      </div>
      <div className="flex-row end">
        {!visible && 
          <i
            className="fa-solid fa-plus more-icon"
            onClick={() => {
              setVisibility(true);
            }}
          ></i>}
        {visible && <i className="fa-solid fa-minus" onClick={()=>setVisibility(false)}></i>}
      </div>
      {visible && (
        <div className="content">
          <ReactMarkdown components={{a: LinkRenderer}}>{props.section.fields.content}</ReactMarkdown>
          {props.section.fields.example && <div><h4>Projects and Partnerships</h4>
          <div className="flex-row">
          {props.section.fields.example.map((project, i) => {
            return(     <div key={i} className="transform-partner-button" >
           {project.fields.value !== "0" &&  <a className="white" href={project.fields.value} target="_blank" rel="noopener noreferrer">{project.fields.label}</a>}
           {project.fields.value === "0" &&  <span className="white">{project.fields.label}</span>}
          {/* in the future: can add projects to this button or other items */}
        </div>)
          })}
          </div>
          </div>}
        </div>
      )}
    </div>
  );
}
export default MissionDetails;

import React, { useEffect, useState } from "react";
import "./featured-projects.css";
import FeaturedProjectDetail from "./FeaturedProjectDetail";


function FeaturedProjects(props) {
  const [featuredProjects, setFeatured] = useState([]);
  useEffect(() => {
    const contentful = require("contentful");
    const client = contentful.createClient({
      space: "7urij08wib2s",
      accessToken: "RlThfQO4Z7KjHdZxPiO6bTvDDxBAwJX52q7E4qX5274",
    });
    // get Projects
    let projectsData = [];
    let projects = [];
    client
      .getEntries({ content_type: "project" })
      .then((response) => {
        projectsData = response.items;
        handleProjects();
      })
      .catch(console.error);
    function handleProjects() {
      for (let i = 0; i < projectsData.length; i++) {
        if (projectsData[i].fields.featured) {
          projects.push(projectsData[i].fields);

        }
      }
      projects.sort((a,b)=>a.order - b.order);
      setFeatured(projects);
    }
  }, []);
  return (
    <div className="container">
  <div id="highlights">
      {featuredProjects.map((project, i) => {
        return (
          <FeaturedProjectDetail key={i} index={i} project={project} history={props.history}/>
        );
      })}
      <div className="highlight"></div>
    </div>
    </div>


  
  );
}
export default FeaturedProjects;
